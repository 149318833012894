import React from 'react';
import Scattergraph from '../components/Graph';
import ImdbInputForm from '../components/forms/ImdbInputForm';
import imdbService from '../service/omdb-service.js';
import dummyData from '../json/game-of-thrones.json';
import { sendEvent } from '../analytics/react-ga'
const startCase = require('lodash/startCase');
const uuidv4 = require('uuid/v4');
const uuid = uuidv4();

class AppControl extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        tvShowName: "Game of Thrones",
        title: "Game of Thrones IMDB rating per episode",
        data: dummyData,
      };

      this.submitText = this.submitText.bind(this);
    }
  
    async submitText(value) {
      this.props.showWill(true);
      let data = await imdbService.fetchTvShow(uuid, value);
      // TODO: Better error checking here
      if (typeof(data) === "string") {
        this.props.showWill(false);
        alert("Invalid TV show or there is a movie with the same name. Try again.");
        sendEvent('Omdb', 'Failed', 'Search name: ' + value);
      }
      else {
        sendEvent('Omdb', 'Succeeded', 'Search name: ' + value);
        this.setState({
          tvShowName: startCase(value),
          title: startCase(value) + " IMDB rating per episode",
          data: data,
        });
        this.props.showWill(false);
      }
    }

    shouldComponentUpdate(nextProps, nextState) {
      if (nextState.title !== this.state.title)
        return true;
    }

    render() {
      return (
        <div className="App">
          <Scattergraph 
            data={this.state.data}
            title={this.state.title}/>
          <ImdbInputForm 
            submitTitle="Enter a TV show name"
            tvShowName={this.state.tvShowName}
            onSubmitText={this.submitText}
            loading={this.state.loading}/>
        </div>
      );
    }
  }

  export default AppControl;
const axiosLibrary = require('axios');
const axios = axiosLibrary.create({
    baseURL: process.env.REACT_APP_LAMBDA_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
  });

export default {
    async fetchTvShow(uuid, tvShowName) {
        // TODO: Error handle
        let episodeResponse = await axios.get('/omdb-request', {
            params: {
                tvShowName: tvShowName,
                uuid: uuid,
            }
        });
        return episodeResponse.data;
    }
}

import React from 'react';
import './Graph.css';
import createScatterComponents from '../graphing/generate-scatter';
const d3 = require('d3');

class Scattergraph extends React.Component {
    constructor(props) {
        super(props);

        this.clearGraph = this.clearGraph.bind(this);
        this.generateGraph = this.generateGraph.bind(this);
    }

    clearGraph() {
        d3.select("div#graph")
            .selectAll("*")
            .remove();
    }

    generateGraph(props) {
        createScatterComponents(props)
            .forEach(item => item.renderTo("div#graph"));
    }

    componentWillUpdate(props) {
        this.clearGraph();
        this.generateGraph(props);
    }

    componentDidMount() {
        this.clearGraph();
        this.generateGraph(this.props);
    }

    render() {
        return (
            <div style={{ width: '90%', height: '70vh'}} 
                id="graph"/>
            )
    }
}

export default Scattergraph;
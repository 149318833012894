import ReactGA from 'react-ga';

ReactGA.initialize('UA-127149383-1', {
    debug: process.env.NODE_ENV !== 'production',
    testMode: process.env.NODE_ENV === 'test'
});

export function sendEvent(category, action, label) {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
}

export function sendPageView(url) {
    ReactGA.pageview(url);
}
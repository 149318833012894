const reducer = (accumulator, currentValue) => accumulator + currentValue;

/**
 * Calculate the trendline from data points.
 * 
 * I strongly dislike how I'm checking explicitly for `episodeNumber` and `rating` in this class. I think this should have as
 * little knowledge about the data structure as possible.
 * 
 * @param {Dictionary} data Data points
 */
export default function calculateTrend(data) {
    const xs = [];
    const ys = [];
    for (let key in data) {
        // If the episode's rating is 0, either the backend hasn't correctly picked up the rating
        // or no users have rated that episode. Therefore ignore to prevent the trendline from being a meany >:(
        if (data[key].rating === 0)
            continue;
        xs.push(data[key].episodeNumber);
        ys.push(data[key].rating);
    }

    if (xs.length !== 0 && ys.length !== 0) {
        const a = calculateGradient(data, xs, ys);
        const b = calculateIntercept(data, xs, ys, a);

        const trendStartCoord = calculateStartOfTrend(data, a, b);
        const trendFinalCoord = calculateEndOfTrend(data, a, b);
        return {
            firstX: trendStartCoord["x"],
            firstY: trendStartCoord["y"],
            lastX: trendFinalCoord["x"],
            lastY: trendFinalCoord["y"],
        }
    }
}

function calculateGradient(data, xs, ys) {
    const nSigmaxy = data.length * data.map(item => item.episodeNumber * item.rating).reduce(reducer);
    const sigmaXsigmaY = xs.reduce(reducer) * ys.reduce(reducer);
    const nSigmaxSquared = data.length * xs.map(item => item * item).reduce(reducer);
    const sigmaXallSquared = xs.reduce(reducer) * xs.reduce(reducer);

    return ((nSigmaxy - sigmaXsigmaY) / (nSigmaxSquared - sigmaXallSquared));
}

function calculateIntercept(data, xs, ys, a) {
    return (ys.reduce(reducer) - (a * xs.reduce(reducer))) / data.length   
}

function calculateStartOfTrend(data, a, b) {
    const firstX = data[0].episodeNumber;
    const y = (firstX * a) + b;
    return {
      x: firstX,
      y: y,
    };
}

function calculateEndOfTrend(data, a, b) {
    const lastX = data[data.length-1].episodeNumber;
    const y = (lastX * a) + b
    return {
      x: lastX,
      y: y,
    };
}
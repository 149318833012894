import React, { Component } from 'react';
import WillSmith from './components/WillSmith';
import './App.css';
import AppControl from './controls/AppControl';
import { sendPageView } from './analytics/react-ga'

// TODO: Move this further up so that App level doesn't contain the big man himself.
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

    this.showWill = this.showWill.bind(this);
  }

  showWill(isLoading) {
    this.setState({
      loading: isLoading,
    })
  }

  render() {
    sendPageView('/');
    let bigWilly;
    if (this.state.loading) {
      bigWilly = <WillSmith />;
    }
    return (
      <div>
        {bigWilly}
        <AppControl 
          showWill={this.showWill}/>
      </div>
    );
  }
}

export default App;
import calculateTrendline from './trendline';
const Plottable = require('plottable');

const colorRange = [
    "#C62828", "#283593", "#00695C", "#F9A825",
    "#4E342E", "#6A1B9A", "#0277BD", "#558B2F", "#EF6C00",
];

export default function createScatterComponents(props) {
    // Create graph axes and scales
    const xScale = new Plottable.Scales.Linear();
    xScale.domainMin([0]);
    xScale.tickGenerator(Plottable.Scales.TickGenerators.integerTickGenerator());
    const yScale = new Plottable.Scales.Linear();
    yScale.tickGenerator(Plottable.Scales.TickGenerators.integerTickGenerator());
    yScale.domain([0, 11]);
    const xAxis = new Plottable.Axes.Numeric(xScale, "bottom");
    const yAxis = new Plottable.Axes.Numeric(yScale, "left");

    const plots = [];

    for (let seasonData of props.data) {
        addScatterPlot(seasonData, xScale, yScale, plots);
        addTrendlinePlot(seasonData, xScale, yScale, props, plots);
    }

    const xLabel = new Plottable.Components.AxisLabel("Episode number");
    const yLabel = new Plottable.Components.AxisLabel("IMDB rating", 270);

    const mergedPlots = new Plottable.Components.Group(plots);

    const chart = new Plottable.Components.Table([
        [yLabel, yAxis, mergedPlots],
        [null, null, xAxis],
        [null, null, xLabel],
    ]);

    const title = new Plottable.Components.TitleLabel(props.title)
        .yAlignment("top")

    window.addEventListener("resize", () => { 
        chart.redraw();
        title.redraw();
    });

    return [title, chart];
}

function addScatterPlot(seasonData, xScale, yScale, plots) {
    const dataset = new Plottable.Dataset(seasonData);
    // Create scatter diagram
    const scatterPlot = new Plottable.Plots.Scatter()
        .x(d => d.episodeNumber, xScale)
        .y(d => d.rating, yScale)
        .size(10)
        .addDataset(dataset)
        .attr("fill", d => colorRange[d.season % 9])
        .animated(true);

    addOnClickInteraction(scatterPlot);
    plots.push(scatterPlot);
}

function addOnClickInteraction(plot) {
    const interaction = new Plottable.Interactions.Click();
    interaction.onClick((point) => {
        try {
            const dataPoint = plot.entitiesAt(point)[0];
            if (dataPoint !== undefined) {
                const selection = dataPoint.selection._groups[0][0].__data__;
                window.open(selection.imdburl, '_blank');
            }
        } catch (err) {
            console.error(err);
        }
    });
    interaction.attachTo(plot);
}

function addTrendlinePlot(seasonData, xScale, yScale, props, plots) {
    try {
        const trendline = calculateTrendline(seasonData);
        if (trendline !== undefined) {
            const trendlineData = [
                { "x": trendline["firstX"], "y": trendline["firstY"] },
                { "x": trendline["lastX"], "y": trendline["lastY"] },
            ];
            const trendlineDataset = new Plottable.Dataset(trendlineData);
            // Create trendline and add data
            const trendLinePlot = new Plottable.Plots.Line()
                .x(d => d.x, xScale)
                .y(d => d.y, yScale)
                .attr("stroke", colorRange[(props.data.indexOf(seasonData) + 1) % 9])
                .attr("stroke-dasharray", 12)
                .addDataset(trendlineDataset)
                .animated(true);
            plots.push(trendLinePlot);
        }
    }
    catch (error) {
        console.error(error);
    }
}
import React from 'react';
import './ImdbInputForm.css'
import { sendEvent } from '../../analytics/react-ga'

class ImdbInputForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '', 
            loading: false
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.value !== '') {
            this.props.onSubmitText(this.state.value);
            sendEvent('User', 'Searched', 'Search name: ' + this.state.value);
            this.setState({
                value: "",
                loading: true,
            })
        }
    }

    render() {
        return (
            <div className="submission-form">
                <form onSubmit={this.handleSubmit}>
                    <input 
                        placeholder="Enter a TV show name"
                        type="text" 
                        value={this.state.value} 
                        onChange={this.handleChange}/>
                </form>
            </div>        
        );
    }
}

export default ImdbInputForm;
import React from 'react';
import styled from 'styled-components';
import willSmith from '../willsmith/willrun.gif';

const BigWillyContainer = styled.img`
    height: 25%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
`;

export default class WillSmith extends React.Component {
    render() {
        return(
            <div>
                <BigWillyContainer src={willSmith}/>
            </div>
        )
    }
}